import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.am730.com.hk/news/%E6%96%B0%E8%81%9E/%E3%80%8C%E8%B7%AF%E9%81%8E%E5%8C%97%E8%A7%92%E3%80%8D%E8%97%9D%E8%A1%93%E9%A0%85%E7%9B%AE%E9%96%8B%E5%B9%95-%E6%B3%B3%E6%A3%9A%E8%88%8A%E6%AB%88%E5%82%B3%E6%89%BF%E7%A4%BE%E5%8D%80%E6%95%85%E4%BA%8B-282563";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
